import { Link } from "@remix-run/react";
import { NavbarChildItem } from "@portal-frontend-ssr/sanity-api";
import { forwardRef, ReactNode, Ref } from "react";
import { getConfig } from "@portal-frontend-ssr/config";
import { isSsrPage } from "@portal-frontend-ssr/helpers";

type NavProps = Pick<NavbarChildItem, "externalLink" | "route" | "title">;

const getLinkUrl = (navbarItem: NavProps): string => {
  if (navbarItem.externalLink) {
    return navbarItem.externalLink.startsWith("/")
      ? navbarItem.externalLink
      : new URL(navbarItem.externalLink).pathname;
  } else {
    return navbarItem.route ?? "#";
  }
};

export const NavLink = forwardRef(
  (
    {
      navbarItem,
      children,
      className,
      onClick,
    }: {
      navbarItem: NavProps;
      className?: string;
      children?: ReactNode;
      onClick?: () => void;
    },
    ref: Ref<HTMLAnchorElement>,
  ) => {
    if (navbarItem.externalLink) {
      // External link: open in new tab
      return (
        <a
          href={navbarItem.externalLink}
          target="_blank"
          rel="noreferrer"
          className={className}
          ref={ref}
          onClick={onClick}
        >
          {children}
        </a>
      );
    }

    if (!isSsrPage({ pathname: navbarItem.route, environment: getConfig().ENVIRONMENT })) {
      // Link to SPA site

      const route = navbarItem.route ? `${getConfig().SPA_SITE_URL}${navbarItem.route}` : "#";
      return (
        <a href={route} className={className} ref={ref} onClick={onClick}>
          {children}
        </a>
      );
    }

    return (
      // Internal link
      <Link to={getLinkUrl(navbarItem)} className={className} ref={ref} onClick={onClick}>
        {children}
      </Link>
    );
  },
);

NavLink.displayName = "NavLink";
